import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { API_URLS } from "constants/api-urls";
import { Dayjs } from "dayjs";
import { ItemType } from "pages/app/Invoice/steps/invoice-items/InvoiceItems";
import { torquePrivateApi } from "utils/api";

type SubCustomerAddress = {
  address: string;
  city: string;
  country: string;
  state: string;
  postalCode: string;
};

export type CreateSubCustomerRequest = {
  accountId: number;
  countryId: number | null;
  email: string;
  name: string;
  address: SubCustomerAddress;
};
export type CreateSubCustomerResponse = {
  httpStatus: "OK";
  data: {
    message: string;
    subCustomerId: number;
  };
};

export type InvoiceRequest = {
  subCustomerId: number | null;
  invoiceNumber: string;
  amount: string;
  currencyId: number | null;
  invoiceDate: Dayjs | null;
  invoiceDueDate: Dayjs | null;
  transactionTypeId: number | null;
  purposeCodeId: string | null;
  depositId: string | null;
  discount: number | null;
  tax:number|null;
  items: ItemType[] | null;
};

export interface SubCustomerResponse {
  accountId: number;
  countryId: number;
  createdAt: Date;
  email: string;
  id: number;
  name: string;
}

export type FetchSubCustomerResponse = {
  httpStatus: "OK";
  data: SubCustomerResponse[];
};
export type FetchSubCustomerByIdResponse = {
  httpStatus: "OK";
  data: SubCustomerResponse;
};

export interface UploadInvoiceFileResponse {
  invoiceId: string;
}

export interface AccountTransactions {
  date: Dayjs;
  payer: string;
  transactionType: string;
  amount: number;
  countryId: number;
}

export interface Balance {
  amount: string;
  countryId: number;
}

export type InvoiceItems = {
  hsn?: string;
  name?: string;
  description?: string;
  id: number;
};

export type FetchBalanceResponse = {
  httpStatus: "OK";
  available: Balance | Balance[];
};

export type FetchDepositResponse = {
  httpStatus: "OK";
  deposits: AccountTransactions[];
  count: number;
};

export interface FetchInvoiceItemsResponse {
  httpStatus: "OK";
  data: InvoiceItems[];
}

export const InvoiceService = {
  GetSubCustomers: function (params?: AxiosRequestConfig) {
    return torquePrivateApi.get<FetchSubCustomerResponse>(
      API_URLS.invoice.subCustomer,
      params
    );
  },
  GetSubCustomerById: function (
    subCustomerId: number,
    params?: AxiosRequestConfig
  ) {
    return torquePrivateApi.get<FetchSubCustomerByIdResponse>(
      `${API_URLS.invoice.subCustomer}/${subCustomerId}`
    );
  },
  CreateSubCustomer: function (
    data: CreateSubCustomerRequest,
    params?: AxiosRequestConfig
  ) {
    return torquePrivateApi.post<
      CreateSubCustomerRequest,
      CreateSubCustomerResponse
    >(
      API_URLS.invoice.subCustomer,
      {
        ...data,
      },
      params
    );
  },
  UploadInvoiceFile: function (data: FormData, params?: AxiosRequestConfig) {
    return torquePrivateApi.post<UploadInvoiceFileResponse>(
      API_URLS.invoice.invoice,
      data
    );
  },
  CreateInvoice: function (data: InvoiceRequest) {
    return torquePrivateApi.post<InvoiceRequest>(
      API_URLS.invoice.invoice,
      data
    );
  },
  GetInvoiceById: function (invoiceId: number, params?: AxiosRequestConfig) {
    return torquePrivateApi.get(
      `${API_URLS.invoice.invoice}/${invoiceId}`,
      params
    );
  },
  GetBalance: function (params?: AxiosRequestConfig) {
    return torquePrivateApi.get<FetchBalanceResponse>(
      API_URLS.invoice.balance,
      params
    );
  },
  GetDeposits: function (params?: AxiosRequestConfig) {
    return torquePrivateApi.get<FetchDepositResponse>(
      API_URLS.invoice.deposits,
      params
    );
  },
  GetInvoiceItems: function (params?: AxiosRequestConfig) {
    return torquePrivateApi.get<FetchInvoiceItemsResponse>(
      API_URLS.invoice.invoiceItems,
      params
    );
  },
};

export default InvoiceService;
